import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout/layout"

import Horses from "../components/Horses/Horses"

const HorsesSalePage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout seo={data.strapiHomepage.seo}>
        <h1 className="title-page">Konie na sprzedaż</h1>
      <div className="uk-container uk-container-small">
        <Horses horses={data.allStrapiHorsesSale.edges} />
      </div>
    </Layout>
  )
}

const query = graphql`
    query {
        strapiHomepage {
            seo {
                metaTitle
                metaDescription
            }
        }
        allStrapiHorsesSale(sort:{fields: position, order: ASC}){
            edges {
                node {
                    strapiId
                    name
                    content
                    image {
                        localFile{
                            childImageSharp {
                                fixed(width: 220, height: 290) {
                                    src
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default HorsesSalePage